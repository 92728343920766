import React, { Component } from 'react'
import Col from 'react-bootstrap/col'
import Row from 'react-bootstrap/row'

export default class Experience extends Component {

    render() {
      return (
        <div>
            <Row>
                <Col className="resume-content">
                    <h3>{this.props.data.title}</h3>
                    <div className="subheading mb-2">{this.props.data.employer}, {this.props.data.city}, {this.props.data.country}</div>
                 </Col>
                <Col className="resume-date text-md-right">
                    <span className="text-primary">{this.props.data.date_start} - {this.props.data.date_end}</span>
                </Col>
            </Row>
            <Row className="mb-3">
                <ul>
                     {this.props.data.duties.map ((duty, index) =>
                        { if (duty.keyword.includes(this.props.check)) {
                                return (
                                    <div key={this.props.data.section+"_d_"+index}>{duty.detail}</div> 
                                );
                            }
                        }
                    )}
                </ul>
            </Row>
        </div>
      )
    }
}