import React, { Component } from 'react'
import Button from 'react-bootstrap/button'
import ButtonGroup from 'react-bootstrap/buttongroup'

export default class ProfileButton extends Component {
    render() {
    var current = this.props.active; 
    var getVariant = function(index) {
      if ( index === current )
        return "primary";
      return "secondary";
     };
    return (
        <ButtonGroup 
            className={this.props.nameClass}
            size={this.props.size} 
            aria-label={this.props.ariaLabel}>
            {this.props.profiles.map ((profile, index) =>
            <Button 
                href="#experience"
                variant={getVariant(index)}
                key={index}
                onClick={() => this.props.onClick(index)}
                >
                    {profile}
            </Button>
            )}
    </ButtonGroup>
    );
  }
}