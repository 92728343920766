import React from 'react';
import './App.css';
import Sidebar from './components/sidebar'
import Content from './components/content'
// import About from './components/about'
// import Experience from './components/experience'
// import Education from './components/education'
// import Skills from './components/skills'
// import Interests from './components/interests'
import Data from './resume.json'

function App() {
  return (
    <div className="App">
        <Sidebar></Sidebar>
        <Content data={Data}></Content>
        {/* <div className="container-fluid p-0">
          <About firstName={Data.firstName} 
            lastName={Data.lastName} 
            contact={Data.contact}
            profiles={Data.profiles}
            resumeName={Data.resumeName}
            resumeSummary={Data.resumeSummary}
            socialMedia={Data.socialMedia}>
          </About>
          <Experience experiences={Data.experiences} ></Experience>
          <Education education={Data.education} trainings={Data.training} certifications={Data.certification}></Education>
          <Skills expertises={Data.expertises} toolsSet={Data.toolsSet} devTools={Data.devTools} languages={Data.languages}></Skills>
          <Interests volunteering={Data.volunteering} interests={Data.interests}></Interests>
        </div> */}
    </div>
  );
}

export default App;
