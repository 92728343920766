import React, { Component } from 'react'
import Button from 'react-bootstrap/button'
import CardColumns from 'react-bootstrap/CardColumns'
import Card from 'react-bootstrap/Card'

export default class Toggleprojects extends Component {
    constructor(props){
        super(props);
        this.state={
            dataDisplay: false,
        }
    }

    ToggleButton(){
        this.setState((currentState) => ({
            dataDisplay: !currentState.dataDisplay, 
        }));
    }
    
    render() {
      const isActive=this.state.dataDisplay;
      let feedback;
      let buttonText;
      if (isActive) {      
            feedback = <CardColumns className="">
                {this.props.dataProject.map ((project, index) =>
                <Card key={"xp_project_"+index} border="light" bg="light" text="dark">
                    <Card.Header as="h6">{project.title}</Card.Header>
                    <Card.Body>{project.detail}</Card.Body>
                    {this.props.quote ? 
                    <Card.Footer>
                        <small className="text-muted">{project.quote}</small>
                    </Card.Footer>
                    : null}
                </Card>
            )}
        </CardColumns>;
            buttonText="Hide projects details";
        } else {      
            buttonText="See projects details";
        }
      return (
        <div id={"xp_projects"+this.props.index}>
            <Button onClick={ () => this.ToggleButton() } variant="light">
                {buttonText} 
            </Button>
            {feedback}
        </div>      
    );
  }
}