import React, { Component } from 'react'
import Col from 'react-bootstrap/col'
import Row from 'react-bootstrap/row'
import Container from 'react-bootstrap/container'
import Button from 'react-bootstrap/button'
import ProfileButton from './profileButton.jsx'
import Toggleprojects from './projects.jsx'
import Experience from './experience.jsx'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { PieChart } from 'react-minimal-pie-chart'

function isEven(value) {
    if (value%2 === 0)
        return true;
    else
        return false;
}

export default class Content extends Component {
    constructor() {
        super();
        this.state = {
            active:0,
        };
        this.handleClick = this.handleClick.bind(this);
      }

    handleClick(index) {
        const newState = this.state.active === index ? true : index;
        this.setState({active: newState});
    }
    
    render() {
      return (
        <div className="container-fluid p-0">
            <section className="resume-section p-3 p-lg-5 d-flex align-items-center" id="about">
                <div className="w-100">
                    <h1 className="mb-0">{this.props.data.firstName}
                        <span className="text-primary"> {this.props.data.lastName}</span>
                    </h1>
                    <div className="subheading mb-4">{this.props.data.contact.street} · {this.props.data.contact.city}, {this.props.data.contact.zipCode} · {this.props.data.contact.tel} ·
                        <a href="mailto:simon.defrenet@gmail.com"> {this.props.data.contact.email}</a>
                    </div>
                    <p className="lead mb-4">{this.props.data.resumeSummary}</p>
                    <div>
                        <Container>
                            <Row><Col className="mb-5">
                                    <Button variant="outline-secondary" href={this.props.data.resumeName}> <i className="fa fa-download"/> Download my resume</Button>
                                </Col></Row>
                            <Row><Col className="social-icons mb-5">
                                        {this.props.data.socialMedia.map ((media, index) =>
                                            <a key = {index} className="social-icons" href={media.URL}>
                                                <i className={media.icon} />
                                            </a>
                                        )}
                                </Col></Row>
                            <Row>
                                <Button variant="light" className="btn btn-default btn-lg btn-block" href="#experience">
                                    <a href="#experience"> <i class="fas fa-arrow-down"></i></a>
                                </Button>
                            </Row>
                        </Container>
                    </div>
                </div>
            </section>
            
            <section className="resume-section p-3 p-lg-5 d-flex justify-content-center" id="experience">
                <div className="w-100">
                    <ProfileButton profiles={this.props.data.profiles} 
                            onClick={this.handleClick} 
                            active={this.state.active}
                            nameClass="btn-sm" 
                            size="lg" 
                            ariaLabel="Profiles">
                            </ProfileButton>
                    <div className="mb-5"></div>
                    <h2 className="mb-4">Experience</h2>
                    <ul>
                        {this.props.data.experiences.map ((experience, index) =>
                            <li key={"xp"+index} className="resume-item d-flex flex-column justify-content-between mb-3"> 
                                <Experience data={experience} index={index} section="experience" check={this.props.data.profiles[this.state.active]}></Experience>
                                <Toggleprojects className="mb-3" index={index} dataProject={experience.projects}></Toggleprojects>
                            </li>
                        )}
                    </ul>
                </div>
            </section>
        
            <section className="resume-section p-3 p-lg-5 d-flex align-items-center" id="education">
                <div className="w-100">
                    <h2 className="mb-4">Education</h2>
                    <h3 className="mb-2">Degrees</h3>
                    <ul className="resume-item d-flex flex-column flex-md-row justify-content-between mb-3">
                      {this.props.data.education.map ((degree, index) =>
                        <Row key={"degree"+index} className="resume-item d-flex flex-column flex-md-row justify-content-between mb-3">
                            <div className="resume-content">
                                <h4 className="mb-0">{degree.institution}</h4>
                                <div className="subheading mb-2">{degree.type}, {degree.city}, {degree.country}</div>
                                <h5 className="mb-0">{degree.degree}</h5>
                                <div>{degree.major}</div>
                            </div>
                            <div className="resume-date text-md-right">
                                <span className="text-primary">{degree.startDate} - {degree.endDate}</span>
                            </div>
                            <p>Core subjects: {degree.coreSubjects.join(", ")}</p>
                      </Row>
                      )
                    }
                    </ul>
                    <h3 className="mb-2">Certifications</h3>
                    <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-3">
                      <ul>
                        {this.props.data.certification.map ((certif, index) =>
                        <div key={"certif"+index}>{certif.certificate} · {certif.institution}</div>
                        )}
                      </ul>
                    </div>
                    
                    <h3 className="mb-2">Trainings</h3>
                    <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-3">
                      <ul>
                        {this.props.data.training.map ((training, index) =>
                        <div key={"training"+index}>{training.certificate} · {training.institution}</div>
                        )}
                      </ul>
                    </div>
                   
                   </div>
            </section>
        
            <section className="resume-section p-3 p-lg-5 d-flex align-items-center" id="skills">
                <div className="w-100">
                    <h2 className="mb-4">Skills</h2>
                    <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-3">
                        <Row>
                            <Col className="resume-content subheading">
                                <h3 className="mb-3">Expertise </h3>
                                <PieChart style={{border:'none !important', padding:'none!important' , margin:'none!important'}} 
                                    viewBoxSize={[45,18]} center={[20,5]} 
                                    paddingAngle="5" lineWidth="25" totalValue="100" radius="6" startAngle={0}
                                    label={({ dataEntry }) => dataEntry.title + '\n' + dataEntry.value + "%"} labelStyle={{fontSize:'0.1rem'}} labelPosition="110" 
                                    data={this.props.data.expertises} />
                            </Col>
                            <Col className="resume-content">
                                <h3 className="mb-3">Tools</h3>
                                <ul>
                                    {this.props.data.toolsSet.map ((toolSet, index) =>
                                        <div key = {'toolSet'+index}> {toolSet}</div>
                                    )}
                                </ul>
                            </Col>
                        </Row>
                    </div>

                    <h3 className="mb-3">Programming Languages &amp; Tools</h3>
                       <ul className="dev-icons mb-3">
                           {this.props.data.devTools.map ((devTool, index) =>
                             <li className="list-inline-item">
                                <i className={"fab fa-"+devTool}/>
                             </li>
                           )}
                        </ul>
                        
                    <h3 className="mb-3">Languages</h3>
                    <ul className="fa-ul">
                        {this.props.data.languages.map ((language, index) =>
                            <li key={"language_"+index}> {language.name} 
                            <ProgressBar animated={language.inprogress} id={isEven(index) ? "evenbar" : "oddbar"} now={language.level} />
                          </li>
                        )
                        }
                    </ul>
                </div>
            </section>
        
            <section className="resume-section p-3 p-lg-5 justify-content-center" id="interests">
                <div className="w-100">
                    <h2 className="mb-3">Interests</h2>
                    <p>{this.props.data.interests}</p>
                    <br/>
                    <h3 className="mb-4">Volunteering</h3>
                    <ul>
                        {this.props.data.volunteering.map ((experience, index) =>
                            <li key={"volunteering_"+index} className="resume-item d-flex flex-column justify-content-between mb-3"> 
                                <Experience data={experience} index={index} section="Volunteering" check={this.props.data.profiles[this.state.active]}></Experience>
                            </li>
                        )}
                    </ul>
                </div>

            </section>
           
            <div>      
                    <Button variant="light" className="btn btn-default btn-lg" href="#experience">
                                    <i class="fas fa-arrow-up"></i>
                                </Button>
                </div>        

        </div>
        )
    }   
}