import React, { Component } from 'react'
// import ProfileButton from './profileButton.jsx'


const imgProfile = "img/profile.png";

export default class Sidebar extends Component {
    // constructor() {
    //     super();
    //     this.state = {
    //         active:0,
    //     };
    //     this.handleClick = this.handleClick.bind(this);
    //   }

    // handleClick(index) {
    //     const newState = this.state.active === index ? true : index;
    //     this.setState({active: newState});
    // }

    render() {
      return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-dark bg-primary fixed-top" id="sideNav">
                <a className="navbar-brand js-scroll-trigger" href="#page-top">
                    <span className="d-block d-lg-none">Simon Defrenet</span>
                    <span className="d-none d-lg-block">
                        <img className="img-fluid img-profile rounded-circle mx-auto mb-2" src={imgProfile} alt="" />
                    </span>
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" />
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link js-scroll-trigger" href="#about">About</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link js-scroll-trigger" href="#experience">Experience</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link js-scroll-trigger" href="#education">Education</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link js-scroll-trigger" href="#skills">Skills</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link js-scroll-trigger" href="#interests">Interests</a>
                        </li>
    </ul>
                </div>
                {/* <div className="collapse navbar-collapse">
                    <ProfileButton 
                    profiles={this.props.data.profiles} 
                    onClick={this.handleClick} 
                    active={this.state.active}
                    nameClass="btn-sm" 
                    size="sm" 
                    ariaLabel="Nav"
                    >
                                </ProfileButton>
                </div> */}
            </nav>
        </div>
      )
    }
}